body::before{
  display: block;
  content: '';
  height: 60px;
}

.logo{
  width: 60px;
  object-position: center;
}

.myName{
  font-size: 20px;
  font: bold;
}

.me{
  border-radius: 50%;
}

#skills{
  background-color: #536DFE;
}

a{
  text-decoration: none;
}
a:hover{
  color: #007bff;
}